// ----------------------------------------------------------------------------------------------------------
// This file contains logic for calling the Web PKI component. It is only an example, feel free to alter it
// to meet your application's needs.
// ----------------------------------------------------------------------------------------------------------

//ABS changing to function
//var signatureForm = (function() {
function signatureForm() {

    // Auxiliary global variable.
    var formElements;

    // Create an instance of the LacunaWebPKI object.
    var pki = new LacunaWebPKI();

    // ------------------------------------------------------------------------------------------------------
    // Initializes the signature form.
    // ------------------------------------------------------------------------------------------------------
    function init(fe) {

        // Receive from parameters received as arugments.
        formElements = fe;

        // Wireup of button clicks.
        //formElements.signButton.click(sign);
        formElements.signButton.addEventListener("click",sign);
        //formElements.refreshButton.click(refresh);
        formElements.refreshButton.addEventListener("click",refresh);

        // Block the UI while we get things ready.
        try {
            blockUI('Initializing');
        } catch(e) {
            console.log("Initializing");
        }

        // Call the init() method on the LacunaWebPKI object, passing a callback
        // for when the component is ready to be used and another to be called
        // when an error occurs on any of the subsequent operations. For more
        // information, see:
        // https://docs.lacunasoftware.com/en-us/articles/web-pki/get-started.html#coding-the-first-lines
        // https://webpki.lacunasoftware.com/Help/classes/LacunaWebPKI.html#method_init
        pki.init({
            ready: loadCertificates,    // As soon as the component is ready we'll load the certificates.
            defaultError: onWebPkiError // Generic error callback defined below.
        });
    }

    function blockUI(message){
        window.angularBlockingComponentReference.zone.run(() => { window.angularBlockingComponentReference.loadAngularFunction(message); });
        document.getElementById('zs_lacuna_loader').style.height = '200px';
        document.getElementById('zs_lacuna_loader').style.display = 'block';
    }

    function unblockUI(){
        window.angularUnblockingComponentReference.zone.run(() => { window.angularUnblockingComponentReference.loadAngularFunction(); });
        document.getElementById('zs_lacuna_loader').style.height = '0px';
        document.getElementById('zs_lacuna_loader').style.display = 'none';
    }

    // ------------------------------------------------------------------------------------------------------
    // Function called when the user clicks the "Refresh" button.
    // ------------------------------------------------------------------------------------------------------
    function refresh() {
        // Block the UI while we load the certificates.
        try {
            blockUI('Refreshing');
        } catch(e) {
            console.log("Refreshing");
        }
        // Invoke the loading of the certificates.
        loadCertificates();
    }

    // ------------------------------------------------------------------------------------------------------
    // Function that loads the certificates, either on startup or when the user clicks the "Refresh" button.
    // At this point, the UI is already blocked.
    // ------------------------------------------------------------------------------------------------------
    function loadCertificates() {
        // Call the listCertificates() method to list the user's certificates.
        pki.listCertificates({

            // The ID of the <select> element to be populated with the certificates.
            selectId: /*formElements.certificateSelect.attr('id')*/
                formElements.certificateSelect.attributes['id'].value,

            // Function that will be called to get the text that should be displayed for each option.
            selectOptionFormatter: function (cert) {
                var s = cert.subjectName + ' (issued by ' + cert.issuerName + ')';
                if (new Date() > cert.validityEnd) {
                    s = '[EXPIRED] ' + s;
                }
                return s;
            }

        }).success(function (certs) {
            for(let i = 0; i < certs.length; i++) {
                let cert = certs[i];
                let currentOption = formElements.certificateSelect.options[i];
                if(cert.pkiBrazil.cpf) currentOption.setAttribute('data-cpf', cert.pkiBrazil.cpf);
                if(cert.pkiBrazil.cnpj) currentOption.setAttribute('data-cnpj', cert.pkiBrazil.cnpj);
            }

            // Once the certificates have been listed, unblock the UI.
            try {
                unblockUI();
            } catch(e) {
                console.log("Success: should unblockUI at this point.");
            }

        });
    }

    // ------------------------------------------------------------------------------------------------------
    // Function called when the user clicks the "Sign" button.
    // ------------------------------------------------------------------------------------------------------
    function sign() {

        // Block the UI while we perform the signature.
        try {
            blockUI('Signing');
        } catch(e) {
            console.log("Signing");
        }

        // Get the thumbprint of the selected certificate.
        //var selectedCertThumbprint = formElements.certificateSelect.val();
        var selectedCertThumbprint = formElements.certificateSelect.value;
        // Call signWithRestPki() on the Web PKI component passing the token received from REST PKI and the
        // certificate selected by the user.
        pki.signWithRestPki({
            token: formElements.token,
            thumbprint: selectedCertThumbprint
        }).success(function() {
            // Once the operation is completed, we submit the form.
            //ABS 20210623 Don t submit - leave to Angular Django do it
            //formElements.form.submit();
            window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction(); });
        });
        //return true;
    }

    // ------------------------------------------------------------------------------------------------------
    // Function called if an error occurs on the Web PKI component.
    // ------------------------------------------------------------------------------------------------------
    function onWebPkiError(message, error, origin) {

        // Unblock the UI.
        try {
            unblockUI();
        } catch(e) {
            console.log("onWebPkiError: should unblock UI?");
        }

        // Log the error to the browser console (for debugging purposes).
        if (console) {
            console.log('An error has occurred on the signature browser component: ' + message, error);
        }

        // Show the message to the user. You might want to substitute the alert below with a more
        // user-friendly UI component to show the error.
        alert(message);

        window.angularWebPkiErrorComponentReference.zone.run(() => { window.angularWebPkiErrorComponentReference.loadAngularFunction(); });
    }

    return {
        init: init
    };

//ABS changing to function
//})();

}
